import { makeAutoObservable } from "mobx";

export class Locale {
  code = "en";
  name = "United States";
  currency = "USD";
  currencySymbol = "$";
  cc = "USA";
  currencyName = "US Dollar";

  constructor() {
    makeAutoObservable(this);
  }

  setRegion(locale:locale) {
    this.code = locale.code;
    this.name = locale.name;
    this.cc = locale.cc;
  }

  setCurrency(locale:locale) {
    this.currency = locale.currency;
    this.currencySymbol = locale.currencySymbol;
    this.currencyName = locale.currencyName;
  }

  setLocale(locale:locale) {
    this.setRegion(locale);
    this.setCurrency(locale);
  }
}
