import { makeAutoObservable } from "mobx";

export class Airport {
  displayName = "";
  code = "";
  citynamesshort = "";
  agency = "";

  constructor() {
    makeAutoObservable(this);
  }

  setDisplayName(displayName: string) {
    this.displayName = displayName;
  }
  setCode(code: string) {
    this.code = code;
  }
  setCitynamesshort(citynamesshort: string) {
    this.citynamesshort = citynamesshort;
  }

  setAirport(displayName: string, code: string, citynamesshort: string) {
    this.displayName = displayName;
    this.code = code;
    this.citynamesshort = citynamesshort;
  }

  setAgency(agency: string) {
    this.agency = agency;
  }

  clear() {
    this.displayName = "";
    this.code = "";
    this.citynamesshort = "";
    this.agency = "";
  }
}
