import { makeAutoObservable } from "mobx";

export class SearchLoadingState {
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
