// @ts-nocheck

// Importing global styles
import "../styles/globals.css";

// Importing types and libraries
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query"; // React Query for data fetching
import { ReactQueryDevtools } from "react-query/devtools"; // Devtools for React Query
import { NextIntlClientProvider } from "next-intl"; // i18n provider for Next.js
import { useEffect } from "react";
import TagManager from "react-gtm-module"; // Google Tag Manager
// import { RecoilRoot } from 'recoil' // Recoil for state management

import Toast from "@/components/common/toast"; // A common Toast component for notifications
import { analytics } from "@/utils/firebase";
import { useRouter } from "next/router";

// Create a new query client instance
const queryClient = new QueryClient();

// This is the custom App component. All pages in the app are initialized with it.
const MyApp = ({ Component, pageProps }: AppProps) => {
  // This hook runs once on component mount
  useEffect(() => {
    // Initialize Google Tag Manager with your GTM ID.
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_TAG_MANAGER_ID || "GTM-M67GRTW",
    });
    analytics;
    !(function (e, t, n, s, a) {
      ((a = t.createElement(n)).async = !0),
        (a.src = "https://assets.anytrack.io/BQKf5UTNSF8e.js"),
        (t = t.getElementsByTagName(n)[0]).parentNode.insertBefore(a, t),
        (e[s] =
          e[s] ||
          function () {
            (e[s].q = e[s].q || []).push(arguments);
          });
    })(window, document, "script", "AnyTrack");
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "akg373id40");
  }, []);

  const router = useRouter();

  return (
    <>
      {/* Provide the query client to the React Query provider. */}
      <QueryClientProvider client={queryClient}>
        {/* Provide i18n messages to the NextIntlProvider */}
        <NextIntlClientProvider
          messages={pageProps.messages}
          locale={router.locale}
          timeZone="Europe/Vienna"
        >
          {/* Provide a RecoilRoot for state management */}
          {/* <RecoilRoot> */}
          {/* Render the current page */}
          <Component {...pageProps} />
          {/* Render the Toast component */}
          <Toast />
          {/* </RecoilRoot> */}
        </NextIntlClientProvider>
        {/* Enable the React Query devtools */}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
