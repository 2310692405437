import { makeAutoObservable } from "mobx";

export class Dropoff {
  displayName = "";
  code = "";
  citynamesshort = "";

  constructor() {
    makeAutoObservable(this);
  }

  setDisplayName(displayName: string) {
    this.displayName = displayName;
  }
  setCode(code: string) {
    this.code = code;
  }
  setCitynamesshort(citynamesshort: string) {
    this.citynamesshort = citynamesshort;
  }

  setDropoff(displayName: string, code: string, citynamesshort: string) {
    this.displayName = displayName;
    this.code = code;
    this.citynamesshort = citynamesshort;
  }

  clear (){
    this.displayName = "";
    this.code = "";
    this.citynamesshort = "";
  }
}
