import { Airport } from "@/stores/airport-store";
import { Dropoff } from "@/stores/dropoff-store";
import { Popup } from "@/stores/popup-store";
import { SearchLoadingState } from "@/stores/search-loading-store";
import { Locale } from "@/stores/locale-store";

export const airportStore = new Airport();
export const dropoffStore = new Dropoff();
export const popupStore = new Popup();
export const searchLoadingStore = new SearchLoadingState();
export const localeStore = new Locale();
