import { makeAutoObservable } from "mobx";

export class Popup {
  message = "";
  error = false;
  duration = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setMessage(message: string) {
    this.message = message;
  }
  setError(error: boolean) {
    this.error = error;
  }
  setDuration(duration: number) {
    this.duration = duration;
  }

  setContent(message: string, error: boolean, duration: number) {
    this.message = message;
    this.error = error;
    this.duration = duration;
  }
}
